import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-portal-faq',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, RouterLink],
  templateUrl: './portal-faq.component.html',
  styleUrl: './portal-faq.component.scss'
})
export class PortalFaqComponent {

  email: string = 'support@sprucepower.com';

}
