<app-header></app-header>

<div class="select-option mt-2">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h1 class="faq-heading mt-4">Portal Help & Troubleshooting</h1>
      </div>
      <div class="col-12">
        <hr />
      </div>
      <div class="col-12 p-0 mt-sm-3 mt-sm-5 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="true"
                aria-controls="flush-collapseOne"
              >
                Portal FAQ's
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <strong
                  >These FAQs are specific walkthroughs for how to navigate the
                  Spruce Power Customer Portal. If you are looking for General
                  FAQ’s about Spruce Power or your account in general: <a [routerLink]="['/faq']">click
                  here</a>.</strong
                >
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
              How Do I Login to the portal?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <img fetchpriority="high" decoding="async" class="size-medium alignnone" src="/assets/How-To-Login-scaled-1.jpg" alt="How-To-Login-scaled-1" width="100%">
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
              How Do I view my production?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
            <div class="accordion-body">
              <img fetchpriority="high" decoding="async" class="size-medium alignnone" src="/assets/How-To-View-Your-Production-Date.jpg" alt="How-To-View-Your-Production-Date" width="100%">
            </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
              How Do I Make A Payment?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
            <div class="accordion-body">
              <img fetchpriority="high" decoding="async" class="size-medium alignnone" src="/assets/Make-A-Payment-scaled-1.jpg" alt="Make-A-Payment-scaled-1" width="100%">
            </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
              How Do I Sign Up For E-statements?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
            <div class="accordion-body">
              <img fetchpriority="high" decoding="async" class="size-medium alignnone" src="/assets/EStatement-Sign-up-scaled-1.jpg" alt="EStatement-Sign-up-scaled-1" width="100%">
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
